
.config-viewer-address-item {
    display: flex;
    border-bottom: 1px solid var(--card-border-color);
    padding: 10px 10px 10px 0;

    &--id {
        margin: 0 30px 0 10px;
    }
}

.config-viewer-address-item:last-child {
    padding-bottom: 10;
    border: none;
}
