
.config-content-address {
  &__title {
    margin-bottom: 6px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__icon {
    width: 14px;
    height: 14px;
    transform: translateY(1px);
    margin-right: 3px;
  }
}

.config-address-items {
  display: flex;
  align-items: center;
}
