
.validators-config-table {
    width: 100%;

    thead {
        background-color: transparent !important;
    }

    th {
        border-radius: 0.5rem 0.5rem 0 0;
        padding-top: 0;
    }

    th,
    td {
        padding: 10px 12px !important;
    }

    td {
        border-top: none;
    }

    tr>td:first-child {
        width: 26px;
    }

    .copy-adnl-icon {
        opacity: 0;
        transition: .2s;
    }

    .adnl-cell:hover {
        cursor: default;

        .copy-adnl-icon {
            opacity: 1;
        }
    }
}
