
.jetton-info-container {
    width: 100%;
    align-items: center;
    display: flex;
    flex-grow: 1;
    padding: 18px 0;
    margin-bottom: 8px;
}
.jetton-info-image {
    border-radius: 50%;
    margin: 0 14px 0 0;
    width: 72px;
    height: 72px;
    flex-shrink: 0;
    align-self: flex-start;
}
.jetton-info {
    flex-grow: 1;
    &__name {
        font-weight: 500;
        font-size: 24px;
        margin: 0 0 8px 0;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        overflow: hidden;
    }
    &__description {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        overflow: hidden;
    }
}

.jetton-wallet-search {
    appearance: none;
    border: none;
    background: var(--body-light-muted-color);
    border: 1px solid transparent;
    padding: 5px 10px;
    margin: -6px 0 !important;
    border-radius: 4px;
    font-family: inherit;
    color: inherit;
    width: 100%;
    max-width: 240px;
    box-sizing: border-box;
    transition: .2s all ease;
    &::placeholder {
        color: #939394;
    }
    &::-webkit-input-placeholder {
        color: #939394;
    }
    &:focus {
        max-width: 480px;
        border-color: #2575ed;
        background: transparent;
    }
}

@media all and (max-width: 480px) {
    .jetton-info-container {
        flex-direction: column;
        text-align: center;
        margin-bottom: 6px;
    }

    .jetton-info-image {
        margin: 0 0 12px 0;
        width: 86px;
        height: 86px;
        align-self: center;
    }

    .jetton-info {
        &__name {
            font-size: 26px;
        }
    }

    .jetton-wallet-search {
        margin: 0 !important;
        max-width: 100%;
    }
}
