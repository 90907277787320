
.chart-change-indicator {
    display: flex;
    align-items: center;
    font-weight: 400;
    margin-left: -1px;
    &--red {
        color: #f74b4c;
        .chart-change-indicator__arrow {
            transform: none;
            background: #f74b4c3b;
            &__svg {
                bottom: -1px;
            }
        }
    }
    &--green {
        color: #42bd62;
        .chart-change-indicator__arrow {
            transform: rotate(180deg);
            background: #42bd623b;
        }
    }
    &__arrow {
        margin-right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0;
        padding: 0;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        &__svg {
            position: relative;
            width: 10px;
            height: 9px;
        }
    }
    &__range {
        color: var(--body-muted-text-color) !important;
        margin-left: 0.8ex;
        opacity: .7;
    }
}
