
.file-list {
    margin: 0; 
    padding: 1px 0 0 0; // compensate code viewer border
    list-style: none;
    & > & {
        margin-left: 12px;
    }
    & + & {
        margin-top: 12px;
    }
    &__file {
        padding: 8px 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 6px;
        margin-bottom: 4px;
        border: 1px solid transparent;
        position: relative;
        &:hover, &--active {
            background: var(--code-viewer-file-selector-background-color);
            border-color: var(--code-viewer-file-selector-border-color);
        }
        &::before {
            content: '';
            width: 2px;
            height: 0;
            background: var(--blue-bright);
            position: absolute;
            left: 3px;
            border-radius: 4px;
            transition: .15s height ease;
        }
        &--active::before {
            height: 70%;
        }

        &__svg {
            fill: currentColor;
            margin-right: 6px;
            width: 16px;
            height: 16px;
            flex-shrink: 0;
            .file-list__file--directory & {
                opacity: .7;
                padding-left: 1px;
            }
        }
        &__name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 0;
        }
    }
}
