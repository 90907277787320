
.param-container {
  position: relative;
  width: auto;
  white-space: nowrap;
  overflow-x: auto;
  padding: 4px 0;
}

.param-container>h4:first-child {
  margin-top: 4px;
}

.param-value {
  display: inline-block;
  padding-right: 16px;
}
