
.page-validators-info-card {
    margin-top: 0 !important;
    margin-bottom: 18px;
    flex-grow: 1;
}

.validators-table {
    thead {
        background-color: var(--tx-table-thead-alt-background);
    }

    th {
        border-radius: 0.5rem 0.5rem 0 0;
    }

    th, td {
        padding: 10px 12px !important;
    }

    tr > th:first-child, tr > td:first-child {
        width: 26px;
    }

    tr > th:last-child, tr > td:last-child {
        width: 40px;
    }

    .copy-adnl-icon {
        opacity: 0;
        transition: .2s;
    }

    .adnl-cell:hover {
        cursor: default;

        .copy-adnl-icon {
            opacity: 1;
        }
    }
}
