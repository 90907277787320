
.page-apps-mobile-navigation-container {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
}

.page-apps-mobile-navigation {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: 40px;
    transition-property: max-height;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    &--open {
        max-height: 500px;
    }
}

.page-apps-mobile-navigation-filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
