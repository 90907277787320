
.chart-contract-types {
    display: flex;
    align-items: center;
    padding: 6px 4px 16px 12px;
    margin: auto 0;
    overflow: hidden;
    .chart-legend {
        padding-left: 12px;
    }
}

@media screen and (max-width: 599px) {
    .chart-contract-types {
        flex-direction: column;
        padding: 0;
        margin: 0;
        
        .chart-pie {
            max-width: 220px;;
        }

        .chart-legend {
            width: 100%;
            padding: 10px 12px;
        }
    }
}
