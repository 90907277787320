
.page-address-contract-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &__link {
        display: flex;
        align-items: center;
    }
    .ui-round-image {
        margin: -8px 6px -8px 0;
        width: 20px;
        height: 20px;
        color: inherit;
    }
    &__block {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        &::after {
            content: '•';
            padding: 0 6px;
            color: var(--body-muted-text-color);
        }
        &:last-child::after {
            display: none;
        }
    }
}
