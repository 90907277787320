
.last-blocks-mini-table {
    overflow: hidden;
    border-collapse: collapse;
    width: 100%;

    td, th {
        white-space: nowrap;
        overflow: hidden;
        padding: 10px 16px;
        border-top: 1px solid var(--card-border-color);
        font-size: 14px;
        max-width: 250px;
        text-align: center;
        &:first-child {
            text-align: left;
        }
    }

    th {
        font-weight: 500;
        border: none;
    }

    thead {
        background-color: var(--tx-table-thead-alt-background);
        color: #6c757e;
        border: none;
    }
}
