

.t-delimiter {
  background: var(--card-border-color);
  height: 1px;

  &--vertical {
    height: 100%;
    width: 1px;
  }
}

