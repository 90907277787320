
.all-transactions-table {
    thead {
        background-color: var(--tx-table-thead-alt-background);
    }

    tr:last-child > td {
        border-bottom: 1px solid var(--card-border-color);
    }
}
