
.mugen-scroll {
    display: flex;
    &__button {
        appearance: none;
        text-transform: uppercase;
        cursor: pointer;
        margin: 12px auto;
        text-align: center;
        padding: 10px 22px;
        font-weight: 500;
        background: var(--blue-bright-background);
        color: var(--blue-bright);
        border-radius: 8px;
        font-size: 13px;
        &:hover {
            background: rgba(56, 139, 253, 0.2);
        }
    }
}
