
.suspended-table {
    &__index-cell {
        padding-right: 4px !important;
    }
    &__total {
        background-color: var(--tx-table-thead-alt-background);
    }
}

.suspended-info {
    padding: 0 4px;
    &__title {
        font-size: 28px;
        margin-bottom: 10px;
    }
    &__lead {
        margin-top: 0;
        margin-bottom: 22px;
        font-size: 16px;
        line-height: 1.5;
    }
}
