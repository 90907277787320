
.param-skeleton-container {
    margin-bottom: 30px;
}

.param-skeleton {
    margin-bottom: 10px;

    &__header {
        width: 200px;
        height: 24px;
    }

    &__number {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }

    &__header-container {
        display: flex;
    }

    &__sub {
        width: 75%;
    }

    &__viewer {
        margin-top: 22px;
        height: 80px;
    }
}
