
.app-screenshot {
    border-radius: 12px;
    position: relative;
    scroll-snap-align: center;
    &:first-child {
        margin-left: 25px;
    }
    &__image {
        border-radius: 12px;
        object-fit: cover;
        object-position: center top;

        &--portrait {
            max-width: 372px;
            height: 372px;
        }

        &--landscape {
            max-width: 659px;
            height: 372px;
        }

        &--loading {
            position: absolute;
            top: 0;
            visibility: hidden;
            width: 210px;
            height: 372px;
        }
    }
}

.app-screenshot-skeleton {
    width: 210px;
    height: 372px;
}
