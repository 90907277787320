
.validation-status-table {
    padding: 0 16px 12px 16px;
    border-spacing: 0;

    th {
        text-align: left;
        font-weight: normal;
        opacity: .4;
        padding: 3px;
    }

    td {
        padding: 3px;
    }

    th:first-child,
    td:first-child {
        padding-left: 0
    }

    th:last-child,
    td:last-child {
        padding-right: 0
    }
}
