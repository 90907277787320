

$app-card-min-height: 85px;
$app-card-border-radius: 12px;
$app-card-width: $app-card-min-height;
$app-card-icon-width: $app-card-min-height;
$app-card-icon-height: $app-card-min-height;

.app-card {
    display: flex;
    border-radius: $app-card-border-radius;
    border: 1px solid var(--card-border-color);
    background: var(--card-background);
    cursor: pointer;
    min-height: $app-card-min-height;
    z-index: 1;

    &__title {
        color: var(--body-text-color);
        font-weight: 500;
    }

    &__icon {
        border-radius: 12px;
        width: $app-card-icon-width;
        height: $app-card-icon-height;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0;

        .broken-icon {
            color: #959a9e;
            width: 75%;
            height: 75%;
        }
    }

    &__load {
        background: var(--skeleton-loader-background);
    }

    &__icon-wrapper {
        width: $app-card-width;
        flex-shrink: 0;
    }

    &__heading {
        display: flex;
        align-items: center;
    }

    &__content {
        padding: 10px 16px 10px 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__description {
        margin-top: 6px;
        font-weight: 400;
        font-size: 14px;
        width: 100%;
        line-height: 20px;

        & p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            color: var(--app-card-description);
            margin: 0;
            // width: 95%;
            // display: block;
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // -webkit-line-clamp: 1;
        }
    }

    &__chip {
        margin-left: 6px;
        margin-top: -1px;
    }

    &__icon-verified {
        margin-left: 6px;
        color: var(--app-icon-verified-color);
        transform: translateY(-1px);
        width: 16px;
        height: 16px;
    }

    &__badge {
        text-transform: uppercase;

        &--hot {
            background: rgba(223, 137, 80, 0.24);
            color: rgba(223, 137, 80);
        }

        &--new {
            background: var(--app-card-badge-new-background);
            color: #54B893;
        }

        &--ad {
            // Ad chip will be in future
        }
    }
}

@media screen and (max-width: 480px) {

    $app-card-border-radius-small-screen: 13px;

    .app-card {
        min-height: 96px;

        &__icon {
            width: 64px;
            height: 64px;
            display: flex;
            border-radius: $app-card-border-radius-small-screen;
        }

        &__icon-wrapper {
            padding: 16px 0 16px 16px;
            width: auto;
        }

        &__content {
            //
        }
    }

    .apps-list {
        &__card {
            border-radius: 0;
            border-right: 0;
            border-left: 0;
        }

    }
}
