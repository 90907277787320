
.apps-list__card {
  margin-bottom: 20px;
}

@media screen and (max-width: 480px) {
  .apps-list__card {
    margin: 0 -16px;
    border-radius: 0;
    border-bottom: none;
  }

}
